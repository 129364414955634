<template>
	<div class="page edit-page" :style="height">
		<div class="top-wrapper">
			<div class="title"> {{85 > scrollTop ? '公告详细' : ''}} </div>
			<div class="actions">
				<el-button type="primary" plain size="small" class="cancel" @click="goBack"> 返回 </el-button>
			</div>
		</div>

		<div class="content-wrapper">
			<el-row :gutter="20" type="flex" class="content-row">
				<el-col :lg="15" :md="15" :sm="15" :xs="24">
					<div class="detail-container pa20 pb0">
						<div class="attribute-item">
							<div class="attribute-item__label"> 公告主题 </div>
							<div class="attribute-item__content">
								<span> {{ data.subject || '' }} </span>
							</div>
						</div>
						<el-divider />

						<div class="attribute-item">
							<div class="attribute-item__label"> 分类 </div>
							<div class="attribute-item__content">
								<span> {{ data.typeName || '' }} </span>
							</div>
						</div>
						<el-divider />

						<div class="attribute-item">
							<div class="attribute-item__label"> 公告内容 </div>
							<div class="attribute-item__content">
								<RichTextDisplay :content="data.content"></RichTextDisplay>
							</div>
						</div>
						<el-divider />

						<div class="attribute-item">
							<div class="attribute-item__label"> 涉及机型 </div>
							<div class="attribute-item__content">
								<span> {{ data.deviceNames || '' }} </span>
							</div>
						</div>
						<el-divider />

						<div class="attribute-item">
							<div class="attribute-item__label"> 固件下载地址 </div>
							<div class="attribute-item__content">
								<div v-for="(firmware, index) of data.firmwareList" :key="index">
									<el-link icon="el-icon-link" @click="handleLink(firmware.firmwareLink)" type="primary">
										{{ firmware.firmwareName }} </el-link>
								</div>
							</div>
						</div>
						<el-divider />
					</div>
				</el-col>

				<el-col :lg="9" :md="9" :sm="9" :xs="24">
					<div class="upload-container pa20 pb0">
						<div class="title-wrapper">
							<div class="title">
								附件内容
							</div>

							<img v-if="data.sysAttachFileList && data.sysAttachFileList.length > 1"
								:src="assets.download" @click="download" />
						</div>

						<div v-if="data.sysAttachFileList && data.sysAttachFileList.length > 0">
							<template v-for="(attachment, index) in data.sysAttachFileList">
								<Progress :key="index" :file="attachment" :isServer="true" :percentage="100"
									showFileDownload></Progress>
							</template>
						</div>
						<div v-else class="empty">
							<img :src="assets.emptyImg" class="empty-img" />
							<div class="empty-description">
								暂无附件内容~
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	import Progress from '@/components/Progress'
	import RichTextDisplay from '@/components/RichText/RichTextDisplay'
	import kong from '@/assets/images/kong.png'
	import downloadImg from '@/assets/images/download.png'

	export default {
		components: {
			Progress,
			RichTextDisplay
		},
		data() {
			return {
				// 公告ID
				id: '',
				cached: {
					pageNo: 1
				},
				data: {},
				assets: {
					emptyImg: kong,
					download: downloadImg
				},
				height: '',
				scrollTop: 0
			}
		},
		created() {
			// this.getPassAndKey();
			this.id = this.$route.query.id
			if (this.id) this.getDetail()

			this.cached.pageNo = this.$route.params.pageNo || 1
			this.cached.searchKey = this.$route.params.searchKey || ''
			this.cached.type = this.$route.params.type || ''
			if (this.$route.params.publishFlag === 0) {
				this.cached.publishFlag = this.$route.params.publishFlag
			} else {
				this.cached.publishFlag = this.$route.params.publishFlag || ''
			}

			this.height = 'min-height: ' + (window.innerHeight - 180) + 'px'
		},
		methods: {
			getDetail() {
				if (!this.id) return

				let that = this
				that.$request.post(
					"dataNoticeInfo",
					true, {
						id: that.id
					},
					function (r) {
						if (r.code == "0") {
							that.data = r.data
						} else {
							that.$message.error(r.msg)
						}
					}
				)
			},
			// 下载
			download() {
				const name = this.data.subject + '.zip'

				let that = this
				that.$request.download(
					"dataFileDownload",
					true, {
						linkedId: that.id,
						linkedTable: 'data_notice'
					},
					function (res) {
						if (res) {
							let blob = new Blob([res], {
								type: 'application/zip'
							})
							let url = window.URL.createObjectURL(blob)
							const link = document.createElement('a') // 创建a标签
							link.href = url
							link.download = name // 重命名文件
							link.click()
							URL.revokeObjectURL(url) // 释放内存
						} else {
							that.$message.error(res.msg)
						}
					}
				)
			},
			goBack() {
				this.$router.push({
					name: 'bulletin',
					params: {
						pageNo: this.cached.pageNo,
						searchKey: this.cached.searchKey,
						type: this.cached.type,
						publishFlag: this.cached.publishFlag
					}
				})
			},
			handleLink(url) {
				let that = this;
				that.$request.get(
					"getDownloadKey",
					true, {},
					function (r) {
						const linkNode = document.createElement('a');
						linkNode.style.display = 'none';
						linkNode.href = r.data.path + '?url='+url+'&key='+r.data.key;
						document.body.appendChild(linkNode);
						linkNode.click();  //模拟在按钮上的一次鼠标单击
						URL.revokeObjectURL(linkNode.href); // 释放URL 对象
						document.body.removeChild(linkNode);
					}
				)
			},
			// 处理吸顶时title隐藏
			handleScroll() {
				this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
			}
		},
		mounted() {
			window.addEventListener('scroll',this.handleScroll);
		},
		destroyed() {
			window.removeEventListener('scroll', this.handleScroll)
		}
	}

</script>
